import React from "react";
import Container from "../../components/Container";
import styled from "styled-components";
// import logoDoomie from "../../assets/images/loom.png";
import { Carousel } from "react-responsive-carousel";

const StyledClientes = styled.section`
  .carousel {
    .slide {
      background: none;
    }
  }
  .control-dots {
    .dot {
      background: #ececec;
      box-shadow: none;
      opacity: 1;
    }
    .dot.selected {
      background: #000;
    }
  }
  .clientes {
    display: flex;
    flex-wrap: wrap;

    align-items: flex-start;
    justify-content: center;
    margin-top: 35px;

    .cliente {
      flex: 1 0 50%;
      margin-bottom: 30px;

      display: flex;

      img {
        margin: auto;
        object-fit: contain;
        max-width: 120px;
        filter: grayscale(1);
      }
      &:last-child {
        flex: 0 1 50%;
      }
    }
  }

  h3 {
    font-family: Mont;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 51px;
    color: #000000;

    margin: 0;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 580px) {
    h3 {
      font-size: 40px;
    }

    .clientes {
      max-width: 1100px;
      margin: auto;
      margin-top: 40px;
      margin-bottom: 30px;
      .cliente {
        flex: 1 0 20%;
        margin: 20px;

        &:last-child {
          flex: 1 0 20%;
        }
      }
    }
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    .clientes {
      .cliente {
        margin: 30px;
        img {
          max-width: 130px;
        }
      }
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    .clientes {
      .cliente {
        margin: 40px;
        img {
          max-width: 140px;
        }
      }
    }
  }
`;

function Clients(props) {
  return (
    <StyledClientes>
      <Container>
        <h3>Nuestros clientes</h3>
        {props.clients ? (
          <Carousel
            style={{ background: "none" }}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            // showIndicators={false}
          >
            {props.pages.map((page) => {
              props.clients.sort((a, b) => a.INDEX - b.INDEX);
              return (
                <div className="clientes" key={`page_${page}`}>
                  {props.clientsPerPage
                    .sort((a, b) => a.INDEX - b.INDEX)
                    .map((idx) => {
                      const client =
                        props.clients[page * props.clientsPerPage.length + idx];
                      if (client) {
                        return (
                          <div key={client.Nombre} className="cliente">
                            <img src={client.Logo.url} alt={client.Nombre} />
                          </div>
                        );
                      }
                    })}
                </div>
              );
            })}
          </Carousel>
        ) : (
          <></>
        )}
      </Container>
    </StyledClientes>
  );
}

export default Clients;
