import React, { Component } from "react";
import SDK from "../SDK";
import Header from "./sections/Header";
import styled from "styled-components";
import GaleriasProyecto from "./sections/ProyectoImagenes";
import DescripcionProyecto from "./sections/ProyectoDescripcion";
import { Helmet } from "react-helmet";

// import IMAGE from "../assets/images/rappi-icon.svg";

const StyledProyecto = styled.div``;

class Proyecto extends Component {
  constructor() {
    super();
    this.state = {
      proyecto: null,
    };
  }
  async componentDidMount() {
    var proyecto = await SDK.getProyecto(this.props.proyecto);
    if (proyecto.length == 0) {
      window.location = "/";
    }
    proyecto = proyecto[0];
    this.setState({ proyecto });
  }

  render() {
    return this.state.proyecto ? (
      <StyledProyecto>
        <Helmet>
          <title>Bahia - Nuestros Proyectos/{this.state.proyecto.Titulo}</title>

          <meta name="description" content={this.state.proyecto.HeroTitle} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            property="og:url"
            content={`http://new.bahia360.mx/blog/${this.state.proyecto.slug}`}
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content={`Bahia - Nuestros Proyectos/${this.state.proyecto.Titulo}`}
          />
          <meta
            property="og:description"
            content={this.state.proyecto.HeroTitle}
          />
          <meta
            property="og:image"
            content={
              "https://res.cloudinary.com/bahia360/image/upload/l_bahia_logo_blanco,g_south_east,w_150,y_40,x_20/" +
              this.state.proyecto.Imagen.url.split("/")[
                this.state.proyecto.Imagen.url.split("/").length - 1
              ]
            }
          />
          <meta
            name="twitter:url"
            content={`http://new.bahia360.mx/blog/${this.state.proyecto.slug}`}
          />
          <meta
            name="twitter:title"
            content={`Bahia - Nuestros Proyectos/${this.state.proyecto.Titulo}`}
          />
          <meta
            name="twitter:description"
            content={this.state.proyecto.HeroTitle}
          />
          <meta
            name="twitter:image"
            content={
              "https://res.cloudinary.com/bahia360/image/upload/l_bahia_logo_blanco,g_south_east,w_150,y_40,x_20/" +
              this.state.proyecto.Imagen.url.split("/")[
                this.state.proyecto.Imagen.url.split("/").length - 1
              ]
            }
          />
        </Helmet>
        <Header
          h4="Nuestros PROYECTOS"
          span={`/${this.state.proyecto.Titulo}`}
          icon={this.state.proyecto.Logo.url}
          imagen={this.state.proyecto.HeroImg.url}
          h1={this.state.proyecto.HeroTitle}
          link="/proyectos"
        />
        <DescripcionProyecto
          presentamos={this.state.proyecto.Titulo}
          title={this.state.proyecto.HeroDescHeading}
          descripcion={this.state.proyecto.HeroDesc}
          imagen={this.state.proyecto.HeroDescImage.url}
        />
        <GaleriasProyecto proyecto={this.state.proyecto} />
      </StyledProyecto>
    ) : (
      <></>
    );
  }
}

export default Proyecto;
