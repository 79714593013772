import React, { useState } from "react";
import styled from "styled-components";
import Button from "./Button";

const StyledNot = styled.div`
  margin: auto;
  margin-bottom: 60px;
  width: 100%;
  max-width: 1100px;
  &:hover .imagen {
    transform: scale(1.04);
  }
  .imagen {
    transition: all 0.3s ease;
  }
  &:last-child {
    margin-bottom: 0;
  }
  p:first-child {
    font-weight: bold;
  }
  small {
    margin-bottom: 15px;
    max-width: 40%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  a::after {
    background: none !important;
  }
  button {
    margin-top: 20px;
  }
  .nota-credenciales {
    flex: 1 0 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .container {
      width: 100%;
      margin: auto;
    }
  }

  @media (min-width: 768px) {
    height: 300px;

    display: flex;
    .imagen {
      display: flex;
      align-items: center;
      flex: 1 0 50%;
    }
    .imagen {
      justify-content: center;
    }
    .nota-credenciales {
      .container {
        padding: 0 10px;
        width: 80%;
        margin: auto;
      }
    }
  }
`;

export default function EntradaPreview({
  nota,
  idx,
  notas,
  whiteFont,
  suggest,
  proyecto,
}) {
  const [meses] = useState([
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ]);

  const link = `/blog/${nota.slug}`;

  var fecha = new Date(nota.created_at);
  fecha = `${
    meses[fecha.getMonth()]
  } ${fecha.getDate()}, ${fecha.getFullYear()}`;

  return (
    <StyledNot
      className="nota"
      key={idx}
      idx={idx + 1}
      notLenght={notas.length}
      suggest={suggest}
    >
      <div className="imagen">
        <img src={nota.Imagen.url} alt={nota.Imagen.name} />
      </div>

      <div className="nota-credenciales">
        <div className="container">
          <p>{nota.Titulo}</p>
          <p>{nota.Autor}</p>
          <small>{fecha}</small>
          <br />
          <a href={link}>
            <Button dark button_text="SEGUIR LEYENDO" whiteFont={whiteFont} />
          </a>
        </div>
      </div>
    </StyledNot>
  );
}
