import React, { Component } from "react";
import styled from "styled-components";
import Container from "../../components/Container";
import Video from "../../components/Video";

import InnovemosSection from "./InnovemosSection";
import OtrosProyecto from "./OtrosProyecto";
import Footer from "./Footer";
import ReactMarkdown from "react-markdown";

const StyledProyectoImages = styled.div`
  /* *{
    border: 1px solid red;
} */

  position: relative;

  .head {
    max-width: 900px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      margin: 0;
      font-family: Mont-light;
      margin-bottom: 30px;
      align-self: flex-start;
    }
    p {
      margin: 0;
      margin-bottom: 30px;
    }

    a {
      cursor: pointer;
      align-self: center;
      justify-self: center;
      text-decoration: none;
      color: white;
      margin: 10px 0;
      font-weight: bold;
      span {
        color: #a04d9a;
      }

      ::after {
        content: "";
        display: block;
        width: 0;
        height: 3px;
        background: white;
        transition: width 0.3s;
      }
      :hover::after {
        width: 100%;
        //transition: width .3s;
      }
    }
  }

  .imagenes {
    margin-left: auto;
    .imagen {
      margin-bottom: 30px;
      display: flex;
      img,
      video {
        object-fit: contain;
        max-width: 100%;
        margin: auto;
      }
    }
  }

  .metrics {
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
    .metric {
      background: #3345da;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      flex: 1 0 30%;

      height: 70px;

      margin-bottom: 20px;
      padding: 10px;
      align-items: center;
      justify-content: center;

      span {
        font-weight: bold;
        margin-bottom: 10px;
      }
      p {
        text-align: center;
        margin: 0 !important;
      }
    }

    .metric:nth-child(odd) {
      margin-right: 20px;
    }
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
    .head {
      position: sticky;
      top: 0;
      height: 100vh;
      margin: 0;
      max-width: 40%;
      padding-right: 60px;
      h1 {
        margin-bottom: 60px;
      }
      p {
        margin-bottom: 60px;
      }
    }

    .imagenes {
      .imagen {
        margin-bottom: 60px;
      }
    }
    .black-container,
    .gray-container {
      display: flex;
    }

    .metric {
      flex: 0 0 30% !important;
      height: 80px !important;
      padding: 15px !important;
    }
  }
  .gray-container {
    .head {
      margin-top: 6rem;
    }
  }

  @media (min-width: 992px) {
    .imagenes {
      margin-top: 6rem;
    }

    .black {
      .head {
        h1 {
          margin-top: 100px;
        }
      }
    }
  }
`;

const StyledBlack = styled.div`
  background: #000000;
  color: white;
  position: sticky;
`;

const StyledGray = styled.div`
  position: sticky;
  background: #e2e2ea;
`;

class PrjectImages extends Component {
  render() {
    return (
      <StyledProyectoImages>
        <StyledBlack className="black">
          <Container className="black-container">
            <div className="head">
              <h1>{this.props.proyecto.DescriptionTitle}</h1>

              <div className="text">
                <ReactMarkdown>
                  {this.props.proyecto.DescriptionText}
                </ReactMarkdown>
              </div>
              {this.props.proyecto.DescriptionMetrics ? (
                <div className="metrics">
                  {Object.keys(this.props.proyecto.DescriptionMetrics)
                    .sort()
                    .map((k) => {
                      return (
                        <div key={"metric_" + k} className="metric">
                          <span>
                            {this.props.proyecto.DescriptionMetrics[k].number}
                          </span>
                          <p>
                            {this.props.proyecto.DescriptionMetrics[k].title}
                          </p>
                        </div>
                      );
                    })}
                </div>
              ) : null}

              <a
                href="https://www.behance.net/bahiahub"
                target="_blank"
                rel="noopener noreferrer"
              >
                CONOCE NUESTRO BEHANCE{" "}
                <span style={{ color: "#A04D9A" }}>&rarr;</span>{" "}
              </a>
            </div>

            <div className="imagenes">
              {this.props.proyecto.DescriptionImages.sort((a, b) =>
                a.name > b.name ? 1 : -1
              ).map((img) => (
                <div className="imagen" key={img.name}>
                  {img.url.indexOf("mp4") === -1 ? (
                    <img src={img.url} alt="" />
                  ) : (
                    <Video video={img.url} controls={true} />
                  )}
                </div>
              ))}
            </div>
          </Container>
        </StyledBlack>
        <StyledGray className="gray">
          <Container className="gray-container">
            <div className="head">
              <h1>{this.props.proyecto.DesignTitle}</h1>

              <p>{this.props.proyecto.DesignDescription}</p>
            </div>
            <div className="imagenes">
              {this.props.proyecto.DesignImages.sort((a, b) =>
                a.name > b.name ? 1 : -1
              ).map((img) => (
                <div className="imagen" key={img.name}>
                  <img src={img.url} alt="" />
                </div>
              ))}
            </div>
          </Container>
          <OtrosProyecto proyecto={this.props.proyecto} />

          <div className="Proyecto-footer">
            <InnovemosSection />
            <Footer />
          </div>
        </StyledGray>
      </StyledProyectoImages>
    );
  }
}

export default PrjectImages;
