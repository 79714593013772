import React from "react";
import styled from "styled-components";
import Button from "../components/Button";

const StyledProject = styled.div`
  padding: 50px 50px;
  width: 40%;
  img {
    width: 100%;
  }

  .imagen {
    transition: all 2s ease;
  }

  &:hover .imagen.par {
    transform: rotateY(-5deg);
  }

  &:hover .imagen.non {
    transform: rotateY(5deg);
  }

  button {
    margin: 50px 0;
  }

  perspective: 900px;

  // Small devices (landscape phones, 576px and up)
  @media (max-width: 1200px) {
    width: 35%;
  }

  @media (max-width: 767.98px) {
    padding: 50px 0;
    width: 40%;
    img {
      width: 100%;
    }
    h3 {
      padding-bottom: 20px;
    }
    button {
      padding: 20px 0;
    }
  }

  @media (max-width: 575.98px) {
    width: 100%;
  }
`;

export default function ProjectPreview({ project, idx, path }) {
  return (
    <StyledProject className="ProjectPreview">
      <div className={idx % 2 === 0 ? "imagen par" : "imagen non"}>
        <img src={project.Imagen.url} alt={project.Titulo} />
      </div>
      <h3>{project.Titulo}</h3>
      <p>{project.Descripcion}</p>
      <Button
        onClick={() =>
          (window.location = `/${path ? path : "proyecto"}/${project.slug}`)
        }
        dark
        whiteFont
        button_text="CONOCE MÁS"
      />
    </StyledProject>
  );
}
