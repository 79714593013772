import React, { Component } from "react";
import styled from "styled-components";

export const VideoPlay = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 85px;
  cursor: pointer;
  z-index: 2;

  @media (max-width: 768px) {
    display: none;
  }
`;
export const VideoPlayMobile = styled.img`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 50px;
  cursor: pointer;
  z-index: 2;

  @media (max-width: 768px) {
    display: block;
  }
`;

class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoPlaying: false,
    };
    this.video = React.createRef();
  }

  playVideo(mobile) {
    this.video.play();
    if (mobile) {
      if (this.video.requestFullscreen) {
        this.video.requestFullscreen();
      } else if (this.video.mozRequestFullScreen) {
        /* Firefox */
        this.video.mozRequestFullScreen();
      } else if (this.video.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.video.webkitRequestFullscreen();
      } else if (this.video.msRequestFullscreen) {
        /* IE/Edge */
        this.video.msRequestFullscreen();
      }
    }
    this.setState({ videoPlaying: true });
  }
  render() {
    return (
      <div style={{ width: "100%", position: "relative" }}>
        {!this.state.videoPlaying ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              background: "rgba(0,0,0,0.4)",
            }}
          >
            <VideoPlay
              onClick={this.playVideo.bind(this, false)}
              src={require("../assets/images/icn_play.svg").default}
            />
            <VideoPlayMobile
              onClick={this.playVideo.bind(this, true)}
              src={require("../assets/images/icn_play.svg").default}
            />
          </div>
        ) : null}

        <video
          style={{ width: "100%" }}
          ref={(ref) => (this.video = ref)}
          onEnded={() => this.setState({ videoPlaying: false })}
          controls={this.props.controls}
        >
          <source src={this.props.video} type="video/mp4" />
        </video>
      </div>
    );
  }
}

export default Video;

// "https://res.cloudinary.com/bahia360/video/upload/v1627504106/Bahi_I_a_manifiesto_f9b1099268.mp4"
