import React, { Component } from "react";
import styled from "styled-components";
import Container from "../../components/Container";
import SDK from "../../SDK";
import ProjectPreview from "../../components/projectPreview";
import ScrollIndicator from "../../components/ScrollIndicator";
import Entrada from "../../components/EntradaPreview";

const StyledEntradas = styled.div`
  /* * {
    border: 1px solid red;
  } */
  .container {
    position: relative;
    color: white;
    .header {
      margin: 10px 0;
      > h4 {
        color: white;
      }
    }
    .columns {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      padding-top: 110px;
      div:nth-child(2n) {
        margin-top: -150px;
      }

      .ProjectPreview {
        margin-bottom: 50px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    .entradas {
      .nota {
        margin-bottom: 50px !important;
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  @media (min-width: 576px) {
    .ProjectPreview {
      margin-bottom: 0px !important;
    }

    .entradas {
      .nota {
        max-width: calc(100% - 110px) !important;
      }
    }
  }

  @media (max-width: 992px) {
    .container {
      h2 {
        width: 100%;
      }

      .columns {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;

        h2 {
          font-family: Mont;
          font-style: normal;
          font-weight: normal;
          font-size: 40px;
          line-height: 51px;
          width: 100%;
          color: #ffffff;
        }
        div:nth-child(2n) {
          margin-top: -70px;
        }
      }
    }
  }

  @media (min-width: 1200px) {
  }
`;
export default class Entradas extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const entradas = await SDK.getArticulos();

    await entradas.sort(function (a, b) {
      return new Date(b.id) - new Date(a.id);
    });

    this.setState({
      entradas,
    });
  }
  render() {
    return this.state.entradas ? (
      <StyledEntradas>
        <Container className="container">
          <ScrollIndicator top />
          <h4>/BAHÍA TRENDS</h4>
          <div className="header">
            <h4>Conoce lo más trendy</h4>
            <h2>
              <span className="gradientText"> del mundo digital.</span>
            </h2>
          </div>
          <h4 style={{ color: "white" }}>LO MÁS RECIENTE</h4>
          <div className="columns">
            {this.state.entradas.map((entrada, idx) => {
              if (idx <= 1) {
                return (
                  <ProjectPreview
                    list
                    idx={idx + 1}
                    project={entrada}
                    key={idx}
                    path="blog"
                  />
                );
              }
            })}
          </div>

          <div className="entradas">
            {this.state.entradas.map((entrada, idx) => {
              if (idx >= 2) {
                return (
                  <Entrada
                    nota={entrada}
                    idx={idx}
                    key={idx}
                    notas={this.state.entradas}
                    whiteFont
                  />
                );
              }
            })}
          </div>
        </Container>
      </StyledEntradas>
    ) : null;
  }
}
