import React from "react";
import styled from "styled-components";
import WhitePaper from "../../components/whitePaper";
import Container from "../../components/Container";
import Video from "../../components/Video";

const StyledUs = styled.section`
  * {
    /* border: 1px solid red; */
  }
`;

const StyledUsWhite = styled.div`
  padding-bottom: 22%;
  .white-container {
    position: relative;
    h1 {
      margin-top: 0;

      max-width: 900px;
      margin: auto;
      margin-bottom: 30px;
    }

    h3 {
      margin-bottom: 0 !important;
      font-family: Mont-light;
      max-width: 900px;
      margin: auto;
    }

    h4 {
      margin-bottom: 30px;
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 20%;
  }

  @media (min-width: 992px) {
    padding-bottom: 11rem;

    h4 {
      margin-bottom: 40px;
    }
  }

  @media (min-width: 1400px) {
    .white-container {
      h1 {
        max-width: 1100px;
      }

      h3 {
        max-width: 1100px;
      }
    }
  }
`;

const StyledUsGray = styled.div`
  background: #e2e2ea;

  .gray-container {
    position: relative;
  }

  h1 {
    font-family: Mont-light;
    font-style: normal;
    font-weight: 300;
    text-align: center;
    margin: 0;

    margin-top: 33%;
  }

  .video {
    position: absolute;
    top: -40%;
    width: calc(100% - 60px);
    max-width: 900px;
  }

  @media (min-width: 576px) {
    .video {
      width: calc(100% - 100px);
    }
  }

  @media (min-width: 768px) {
    h1 {
      margin-top: 34%;
    }
  }

  @media (min-width: 992px) {
    h1 {
      margin-top: 5%;
    }
    .video {
      position: relative;
      margin: auto;
      margin-top: -14rem;
    }
  }

  @media (min-width: 1200px) {
    h1 {
      margin-top: 35px;
    }
  }

  @media (min-width: 1400px) {
    .video {
      max-width: 1100px;
    }
  }
`;

function Nosotros() {
  return (
    <StyledUs>
      <StyledUsWhite>
        <Container className="white-container">
          <WhitePaper>
            <h4>/NOSOTROS</h4>
            <h1>
              Ofrecemos soluciones integrales y estratégicas de marketing
              digital, desarrollo y lealtad.
            </h1>

            <h3>
              Contamos con expertos en marketing, data, tecnología, plataformas
              digitales y programas de lealtad y creamos un hub de inteligencia
              y creación. La innovación está en nuestro ADN, nos encanta
              proponer, cambiar y crear herramientas que ayuden a las personas a
              experimentar ese momento que no sabían que necesitaban.
            </h3>
          </WhitePaper>
        </Container>
      </StyledUsWhite>

      <StyledUsGray>
        <Container className="gray-container">
          <div className="video">
            <Video video="https://res.cloudinary.com/bahia360/video/upload/v1627504106/Bahi_I_a_manifiesto_f9b1099268.mp4" />
          </div>

          <h1>
            Innovación + data <br />
            creatividad + diseño
          </h1>
        </Container>
      </StyledUsGray>
    </StyledUs>
  );
}

export default Nosotros;
