import React, { useEffect, useState } from "react";

import styled from "styled-components";
import hero_bg from "../../assets/images/hero_bg.png";
import hero_bg_mobile from "../../assets/images/hero_bg_mobile.png";

import Button from "../../components/Button";
import Container from "../../components/Container";
import ScrollIndicator from "../../components/ScrollIndicator";

import smoothscroll from "smoothscroll-polyfill";

// kick off the polyfill!
smoothscroll.polyfill();

const StyledHero = styled.section`
  background-color: #ff3456;
  color: white;
  height: 100vh;
  background: url(${hero_bg_mobile}) 80%;
  background-size: cover;
  display: flex;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  p {
    font-family: Mont;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;

    letter-spacing: 0.2em;
    text-transform: uppercase;
  }

  h1 {
    font-family: Mont-bold;
    font-style: bold;
    font-weight: 800;
    font-size: 52px;
    line-height: 54px;
  }

  @media (min-width: 576px) {
    p {
      font-size: 18px;
    }

    .container {
      justify-content: center;
    }
  }

  @media (min-width: 992px) {
    background: url(${hero_bg}) 80%;
    background-size: cover;

    h1 {
      font-family: Mont-bold;
      font-style: normal;
      font-weight: 800;
      font-size: 65px;
      line-height: 65px;
    }
    .hero-container {
      margin: 0 auto;
    }
  }

  @media (min-width: 1200px) {
    .hero-container {
      width: 80%;
    }
  }

  @media (min-width: 1400px) {
    p {
      font-size: 22px;
    }
    h1 {
      font-size: 90px;
      line-height: 98px;
    }

    .hero-container {
      width: 90%;
    }
  }
`;

export default function Hero() {
  const [Mobile, setMobile] = useState(false);
  const [Safari, setSafari] = useState(false);

  useEffect(() => {
    // console.log(window.innerWidth);
    if (window.innerWidth < 767.98) {
      setMobile(true);
    }

    var sUsrAg = navigator.userAgent;

    if (sUsrAg.indexOf("Chrome") > -1) {
      setSafari(false);
    } else if (sUsrAg.indexOf("Safari") > -1) {
      setSafari(true);
    }
  }, []);

  return (
    <StyledHero className="hero">
      <Container className="container">
        <p>/Innovation HUB</p>
        <div className="hero-container">
          <h1> Innovar es la nueva forma de conectar.</h1>
          {/* <Link to="/about"> */}
          <Button
            onClick={() => {
              document
                .getElementById("myElement")
                .scrollIntoView({ behavior: "smooth" });
            }}
            button_text="CONóCENOS"
            whiteFont={true}
          />
          {/* </Link> */}
        </div>

        <ScrollIndicator />
      </Container>
    </StyledHero>
  );
}
