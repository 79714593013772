import React, { Component } from "react";
import styled from "styled-components";
import Container from "../../components/Container";
import WhitePaper from "../../components/whitePaper";
import Video from "../../components/Video";

const StyledDescripcion = styled.div`
  /* * {
    border: 1px solid red;
  } */
  background: white;
  .container {
    position: relative;
  }
  .title {
    margin: 0 0 2rem 0;
  }

  .body {
    max-width: 100%;
    margin: auto;

    h1 {
      font-size: 25px;
    }
    h1,
    h3 {
      margin: auto;
    }

    h3 {
      font-family: Mont-light;
      margin: 3rem 0;
    }
  }

  .img {
    margin: auto;
    display: flex;
    img,
    video {
      object-fit: contain;
      max-width: 100%;
      margin: auto;
    }
  }

  @media (min-width: 768px) {
    .title {
      margin: 0rem 0 4rem 0;
    }
  }
  @media (min-width: 992px) {
    .img {
      margin-top: 4rem;
    }

    .body {
      max-width: 80%;
      h1 {
        font-size: 30px;
      }
    }
  }

  // @media (min-width: 576px) { ... }

  //

  // // Large devices (desktops, 992px and up)
  // @media (min-width: 992px) { ... }

  // // Extra large devices (large desktops, 1200px and up)
  // @media (min-width: 1200px) { ... }
`;

export default class ProyectoDescripcion extends Component {
  // constructor(props) {
  //   super(props);
  // }
  render() {
    return (
      <StyledDescripcion>
        <Container className="container">
          <WhitePaper>
            <div className="title">
              <h4>/Presentamos {this.props.presentamos}</h4>
            </div>
            <div className="body">
              <h1>{this.props.title}</h1>
              <h3>{this.props.descripcion}</h3>
            </div>

            <div className="img">
              {this.props.imagen.indexOf("mp4") === -1 ? (
                <img src={this.props.imagen} alt="" />
              ) : (
                <Video video={this.props.imagen} controls={true} />
              )}
            </div>
          </WhitePaper>
        </Container>
      </StyledDescripcion>
    );
  }
}
