import React from "react";
import "./App.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  Redirect,
} from "react-router-dom";

// Components
import Navbar from "./components/Navbar";
import Spiner from "./components/Spiner";
import { CustomPreloader } from "react-preloaders";

// pages
import Home from "./pages/Home";
import Servicios from "./pages/Servicios";
import Blog from "./pages/Blog";
import Contactanos from "./pages/Contactanos";
import About from "./pages/About";
import Servicio from "./pages/Servicio";
import Proyectos from "./pages/Proyectos";
import Proyecto from "./pages/Proyecto";
import Entrada from "./pages/Entrada";

function App() {
  return (
    <>
      <div className="App">
        <Router>
          <Navbar />
          <Switch>
            <Route path="/contactanos">
              <Contactanos />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route exact path="/servicios">
              <Servicios />
            </Route>
            <Route exact path="/proyectos">
              <Proyectos />
            </Route>
            <Route exact path="/blog">
              <Blog />
            </Route>
            <Route path="/proyecto/:Proyecto_indicador">
              <ProyectoParam />
            </Route>
            <Route path="/servicio/:servicio_indicador">
              <ServicioParam />
            </Route>
            <Route path="/blog/:entrada_indicador/">
              <EntradaParam />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="*">
              <Redirect to="/"/>
            </Route>
          </Switch>
          <CustomPreloader background="#fff">
            <Spiner />
          </CustomPreloader>
        </Router>
      </div>
    </>
  );
}

function ServicioParam() {
  let { servicio_indicador } = useParams();
  return <Servicio servicio={servicio_indicador} />;
}

function EntradaParam() {
  let { entrada_indicador } = useParams();
  return <Entrada entrada={entrada_indicador} />;
}

function ProyectoParam() {
  let { Proyecto_indicador } = useParams();
  return <Proyecto proyecto={Proyecto_indicador} />;
}

export default App;
