import React from "react";
import Styled from "styled-components";
import Container from "../../components/Container";
import WhitePaper from "../../components/whitePaper";

const StyledServices = Styled.div`
.container {
  position: relative;

  z-index: 1;

  h2{
    font-size: 40px;
    margin-bottom: 30px;
  }
  .descripciones {
    display: flex;
    flex-wrap: wrap;    
    .descripcion{
      margin-bottom: 50px;
      &:last-child{
        margin-bottom: 0px;
      }

      h4{
        margin-bottom: 20px;
      }
      .body {
        display: flex;
        .icon{
        margin-right: 10px;
          img{
            width: 40px;
          }
        }
        .text{
          padding-left: 10px;
          h1{ 
            font-size: 20px !important;
            margin: 0;
            line-height:28px;
          }
          h3{
            line-height:30px;
            font-size: 16px !important;
            font-family: Mont;
            font-weight:normal;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) { 
  .descripcion {
    flex: 1 0 45%; 


    &:last-child{
      flex: 0 0 calc(50% - 15px);
    }
    &:nth-child(odd){
      margin-right: 15px;
    }
  }
 }

@media (min-width: 1200px) { 
  .container {
    position: relative;
    h2{
      margin-bottom: 60px;
    }

  }



    .descripciones{
    width: 80%; 
    margin: auto;
    }


  .descripcion {

    .icon{
          img{
            width: 50px !important;
          }
        }
    h4{
      margin-bottom: 30px !important;
    }
    &:last-child{
      flex: 0 0 calc(50% - 30px);
      margin-right: 0px;
    }
    &:nth-child(odd){
      margin-right: 50px;
    }
  }
 }

@media (min-width: 1400px) { 
  .descripcion {
    margin-bottom: 90px !important;
    &:last-child{
      flex: 0 0 calc(50% - 50px);
      margin-right: 0px;
    }
    &:nth-child(odd){
      
      margin-right: 90px;
    }
  }
 }
`;

const DecripccionService = (props) => {
  return (
    <StyledServices>
      <Container className="container">
        <WhitePaper>
          <h2>{props.titulo}</h2>
          <div className="descripciones">
            {props.tareas
              .sort((t1, t2) => t1.id - t2.id)
              .map((tarea) => {
                return (
                  <div key={tarea.id} className="descripcion">
                    <h4>/{tarea.Titulo.toUpperCase()}</h4>
                    <div className="body">
                      <div className="icon">
                        <img
                          src={tarea.Icon.url}
                          alt={tarea.Icon.alternativeText}
                        />
                      </div>
                      <div className="text">
                        <h1>{tarea.DescHeading}</h1>

                        <h3>{tarea.DescText}</h3>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </WhitePaper>
      </Container>
    </StyledServices>
  );
};

export default DecripccionService;
